




import { Component, Vue } from 'vue-property-decorator'

@Component
export default class YoutubeAuth extends Vue {
  created() {
    window.close()
  }
}
